import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import Link from "gatsby-link";

import logo from "../assets/logo.png"; // Tell webpack this JS file uses this image
import "../style/index.scss";

const Layout = ({ children, site, title }) => (
  <div>
    <Helmet title={`${title} | ${site.siteMetadata.siteName}`} />
    <div className="Container">
      <header class="navbar  container grid-lg">
        <section class="navbar-section"></section>
        <section class="navbar-center">
          <img
            src={logo}
            className="image-responsive navbar-image p-2"
            style={{ maxWidth: 100 }}
            alt="gnesri logo"
          />
        </section>

        <section class="navbar-section">
          <Link
            className="btn btn-link text-uppercase"
            data-text="About"
            to="/about"
          >
            About
          </Link>
          <Link
            className="btn btn-link text-uppercase"
            data-text="Products"
            to="/"
          >
            Products
          </Link>

          <Link
            className="btn btn-link text-uppercase"
            data-text="Restaurants"
            to="/restaurant"
          >
            Businesses
          </Link>
        </section>
      </header>
      <div className="container" style={{ maxWidth: 1024 }}>
        {children}
      </div>
      <div className="hero">
        <div className="hero-body p-centered">
          <div className="Footer">
            ©2020 <a href="https://whoiskaran.com">Karan Wadhwani</a> | Designed
            by <a href="https://www.tylerharnadek.com">Tyler Harnadek</a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

Layout.propTypes = {
  children: PropTypes.func,
};

export default Layout;
